import React from 'react'
import * as S from './Transition.module.scss'
import { useStaticQuery, graphql } from 'gatsby'
import icon from '@/images/svg/icon-1.svg'

const Transition = () => {
  const query = useStaticQuery(graphql`
    {
      contentful00General {
        preloader {
          preloader
        }
      }
    }
  `)

  return (
    <div data-animation='transition' className={S.transition}>
      <div>
        <img data-animation='preloaderImg' width={80} height={80} src={icon} alt='' />
      </div>

      <p
        data-splitting='chars'
        data-animation='transitionParagraph'
        dangerouslySetInnerHTML={{
          __html: query?.contentful00General?.preloader?.preloader,
        }}
      />
    </div>
  )
}

export default Transition
